import { addPropertyControls, ControlType } from "framer"

import {
    InstagramImageGrid,
    propertyControls,
} from "https://scripts.feedspring.co/instagram-framer/image-grid.js#22"

addPropertyControls(FeedspringInstagramImageGrid, propertyControls)

export default function FeedspringInstagramImageGrid(props) {
    return <InstagramImageGrid settings={props} />
}
